import React from "react";
import Card from "react-bootstrap/Card";
import Carousal from "./Carousel";

function Cards({ image, title, text, brand, buttonLink }) {
  return (
    <Card style={{ width: "300px", margin: "10px", height:"570px" }}>
      <Carousal Items={image} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{text}</Card.Text>
        <p style={{ color: "grey", fontSize: "10px" }}>{brand}</p>
      </Card.Body>
    </Card>
  );
}

export default Cards;
