import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import "../css/component/search.css";
import { Spinner } from "react-bootstrap";

function SearchComponent({backendUrl}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const searchRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
        setLoading(true);
      const response = await fetch(backendUrl+`/search?q=${searchQuery}`);
      if (!response.ok) {
        throw new Error("Failed to fetch search results");
      }
      const data = await response.json();
      setSearchResults(data == null ? [] : data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = () => {
    if (searchQuery.trim() !== "") {
      fetchData()
    }
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    } else {
      return text;
    }
  };
  return (
    <div className="search-bar" ref={searchRef}>
      <Form.Control
        type="text"
        placeholder="Search Here..."
        value={searchQuery}
        onChange={handleSearchChange}
      />
        {searchResults.length > 0 && (
        <ListGroup style={{maxHeight:"250px", overflow:"auto"}}>
          {searchResults.map((result) => (
            <ListGroup.Item
              key={result._id}
              action
              href={`/finding/${result._id}`}
              style={{maxHeight: "300px"}}
            >
              <div className="search-result-item">
                {result.images[0] && <div className="search-image-container"><img src={result.images[0]} alt="Result" /></div>}
                <div className="search-result-content">
                  <h5>{truncateText(result.title, 30)}</h5>
                  <p>{truncateText(result.text,40)}</p>
                </div>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
      <button  onClick={handleSearchSubmit} disabled={loading} style={{width:"30%"}}>{loading ? <Spinner size="sm" /> : "Search"}</button>
    </div>
  );
}

export default SearchComponent;
