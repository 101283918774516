import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
<footer className="footer">
  <div className="container">
    <div className="row">
      <div className="col-md-12 text-center">
        <p>&copy; 2024 MinnaStyleHub.co.in. All Rights Reserved. <Link to="/admin-login" style={{ color: 'grey', textDecoration: 'none' }}>Admin Login</Link></p>
      </div>
    </div>
  </div>
</footer>


  )
}

export default Footer