import React from "react";
import Spinner from "react-bootstrap/Spinner";
import '../css/component/spinner.css';
function SpinnerComponent() {
  return (
    <div className="spinner-component">
      <Spinner animation="border" role="status" className="larger-spinner" />
    </div>
  );
}

export default SpinnerComponent;
