import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

function Carousal(props) {
  const [event, setEvent] = useState(0);
  // replace image function
  const replaceImage = (error) => {
    //replacement of broken Image
    error.target.src =
      "https://www.seickel.com/steam-systems/wp-content/uploads/2021/01/no_image_available_28.jpg";
  };
  return (
    <div>
      <Carousel
        data-bs-theme="light"
        onMouseEnter={() => setEvent(2000)}
        onMouseLeave={() => setEvent(0)}
        interval={event === 0 ? null : event}
        pause="false"
      >
        {props.Items.map((val, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={val}
              alt={index}
              onError={replaceImage}
              style={{
                width: "250px", // Adjust width to maintain the 9:16 aspect ratio
                height: "366.67px", // Height calculated to maintain 9:16 aspect ratio
                objectFit: "cover", // Keep aspect ratio while filling the container
                pointerEvents: "none",
              }}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default Carousal;
