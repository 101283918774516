import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./sections/Footer";
import Header from "./sections/Header";
import About from "./pages/About";
import Contact from "./pages/Contact";
import NoFound from "./pages/NoFound";
import Findings from "./pages/Findings";
import Login from "./pages/admin/Login";
import Dashboard from "./pages/admin/Dashboard";
import ProductPage from "./pages/ProductPage"; // Import the ProductPage component

function App() {
  const BE_URL = "https://proj-minnastylehub-backend.onrender.com"
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/findings" element={<Findings backendUrl={BE_URL} />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<Contact backendUrl={BE_URL} />} />
        <Route exact path="/admin-login" element={<Login backendUrl={BE_URL} />} />
        <Route exact path="/admin-dashboard" element={<Dashboard backendUrl={BE_URL} />} />
        {/* Add route for the product page */}
        <Route exact path="/finding/:id" element={<ProductPage backendUrl={BE_URL} />} />
        <Route path="*" element={<NoFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
