import React from "react";
import '../css/pages/notfound.css';

function NoFound() {
  return (
    <div className="not-found-container">
      <h1 className="not-found-title">404 Not Found</h1>
      <p className="not-found-message">Oops! Page not found.</p>
    </div>
  );
}

export default NoFound;
