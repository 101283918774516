import React, { useState, useEffect } from 'react';
import { useNavigate  } from 'react-router-dom'; // Import useHistory for redirection
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import '../../css/admin/login.css'; // Import your CSS file for additional styling

function Login({backendUrl}) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useNavigate(); // Get history object for redirection

  useEffect(() => {
    // Check if a token is stored in localStorage
    const token = localStorage.getItem('token');
    if (token) {
      // If token exists, redirect to admin dashboard
      history('/admin-dashboard');
    }
  }, [history]); // Empty dependency array to run the effect only once on component mount

  const handleLogin = async () => {
    try {
      setError('')
      const response = await fetch(backendUrl+'/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      });

      if (!response.ok) {
        throw new Error('Invalid credentials');
      }

      const data = await response.json();
      // Assuming the server responds with a token and isAdmin flag
      const { token, isAdmin } = data;

      // Store the token in localStorage for subsequent requests
      localStorage.setItem('token', token);

      // Redirect user based on isAdmin status or do other actions
      if (isAdmin) {
        // Redirect to admin dashboard
        history('/admin-dashboard');
      } else {
        // Redirect to regular user dashboard or another page
        
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center vh-80">
      <Card className="login-card">
        <Card.Body>
          <Card.Title className="text-center">Admin Login</Card.Title>
          <FloatingLabel controlId="floatingInput" label="Username" className="mb-3">
            <Form.Control 
              type="email" 
              placeholder="name@example.com" 
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
              required
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingPassword" label="Password">
            <Form.Control 
              type="password" 
              placeholder="Password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required
            />
          </FloatingLabel>
          {error && <div className="text-danger mt-2">{error}</div>}
          <div className="text-left mt-3">
            <Button variant="primary" type='submit' onClick={handleLogin}>Login</Button>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Login;
