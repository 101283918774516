import React, { useState, useEffect } from "react";
import "../css/pages/findings.css";
import Cards from "../components/Cards";
import { Link } from "react-router-dom";
import SpinnerComponent from "../components/SpinnerComponent";
import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
import SearchComponent from "../components/SearchComponent";

function Findings({ backendUrl }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1); // Current page number
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10); // Number of items per page

  useEffect(() => {
    fetchData(page, limit);
    // eslint-disable-next-line
  }, [page, limit]); // Reload data when page or limit changes

  const fetchData = async (page) => {
    try {
      setLoading(true);
      const response = await fetch(
        backendUrl + `/items?page=${page}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const jsonData = await response.json();
      setData(jsonData.result);
      setTotalPages(Math.ceil(jsonData.meta.count / limit)); // Calculate total pages
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    } else {
      return text;
    }
  };

  const handlePagination = (pageNum) => {
    setPage(pageNum); // Update current page number
  };

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value)); // Update limit
    setPage(1); // Reset page to 1 when limit changes
  };

  if (loading) {
    return <SpinnerComponent />;
  }

  return (
    <div className="findings">
      <h2 className="findings-heading">My Findings</h2>
      <hr />
      <div className="top-tools">
      <Form.Group controlId="limitSelect" className="limit-select">
        <Form.Label>Show per page:</Form.Label>
        <Form.Control as="select" value={limit} onChange={handleLimitChange}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
        </Form.Control>
      </Form.Group>
      <SearchComponent backendUrl={backendUrl}/>
      </div>
      <div className="findings-content">
        {data.map((item, index) => (
          <Link
            to={`/finding/${item._id}`}
            key={index}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <Cards
              image={item.images}
              title={truncateText(item.title, 40)}
              text={truncateText(item.text, 50)} // Limiting to 50 characters
              brand={item.brand}
              buttonLink={item.buttonLink}
            />
          </Link>
        ))}
      </div>
      <Pagination className="black-pagination">
        <Pagination.First
          className="custom-pagination-item"
          onClick={() => handlePagination(1)}
        />
        <Pagination.Prev
          className="custom-pagination-item"
          onClick={() => handlePagination(Math.max(page - 1, 1))}
        />
        {[...Array(totalPages)].map((_, i) => (
          <Pagination.Item
            key={i}
            className="custom-pagination-item"
            active={i + 1 === page}
            onClick={() => handlePagination(i + 1)}
          >
            {i + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          className="custom-pagination-item"
          onClick={() => handlePagination(Math.min(page + 1, totalPages))}
        />
        <Pagination.Last
          className="custom-pagination-item"
          onClick={() => handlePagination(totalPages)}
        />
      </Pagination>
    </div>
  );
}

export default Findings;
