import React from "react";
import "../css/pages/home.css";
import comingSoon from "../assets/comingsoon.png";
import FindYourDesign from "../assets/FindYourDesign.png";

function Home() {
  return (
    <div className="home">
      {/* Hero section */}
      <div className="hero-section">
        <div className="hero hero-content">
          <img
            src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/225363/sterling-davis-4iXagiKXn3Y-unsplash-min.jpg"
            alt=""
          />
          <div class="hero__title">
            <h1>Welcome to MinnaStyleHub</h1>
            <p style={{ color: "red" }}>Your destination for trendy fashion</p>
            <a href="#featured-products">Explore Now</a>
          </div>
        </div>
      </div>

      {/* Featured products section */}
      <div className="featured-products" id="featured-products">
        <h2>Featured Products</h2>
        <img src={comingSoon} width={"100%"} alt="" />
      </div>

      <div className="featured-products">
        <img src={FindYourDesign} width={"100%"} alt="" />
      </div>
    </div>
  );
}

export default Home;
