import React, { useState } from "react";
import axios from "axios";
import "../css/pages/contact.css";
import { Spinner } from "react-bootstrap";

function Contact({backendUrl}) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [loading, setLoading] = useState(false);


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(backendUrl+"/feedback", formData);
      console.log(response.data); // Log success message or handle it as needed
      // Optionally, you can reset the form after successful submission
      setFormData({ name: "", email: "", message: "" });
      setLoading(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
      // Handle error, display error message, etc.
    }
  };


  return (
    <div className="contact">
      <div className="contact-card">
        <h2>Contact Us</h2>
        <p>
          If you have any questions, feedback, or inquiries, please feel free to
          get in touch with us. We're here to help!
        </p>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Your Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your name"
            required
          />

          <label htmlFor="email">Your Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            required
          />

          <label htmlFor="message">Your Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Enter your message"
            rows="4"
            required
          ></textarea>

          <button type="submit" disabled={loading} style={{width:"30%"}}>{loading ? <Spinner size="sm" /> : "Send Message"}</button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
