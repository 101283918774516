import React, { useEffect, useState } from "react";
import "../css/pages/product.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import SpinnerComponent from "../components/SpinnerComponent";
import NoFound from "./NoFound";
import { Helmet } from "react-helmet";

const ProductPage = ({ backendUrl }) => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedImage, setExpandedImage] = useState(null);

  useEffect(() => {
    fetchProductData(id); // Fetch product data based on the id
    // eslint-disable-next-line
  }, [id]); // Trigger the effect when id changes

  const fetchProductData = async (id) => {
    try {
      const response = await axios.get(backendUrl + `/item/${id}`);
      setProduct(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setError("Error fetching product data. Please try again later.");
      setLoading(false);
    }
  };

  const handleImageClick = (image) => {
    setExpandedImage(image);
  };

  const handleCloseExpandedImage = () => {
    setExpandedImage(null);
  };

  if (loading) {
    return <SpinnerComponent />;
  }

  if (error) {
    return <NoFound />;
  }

  return (
    <div className="product-page">
      <Helmet>
        <title>{product.title}</title>
        <meta name="description" content={product.text} />
        <meta property="og:title" content={product.title} />
        <meta property="og:description" content={product.text} />
        <meta property="og:image" content={product.images[0]} />
        <link rel="icon" href={product.images[0]} />
      </Helmet>
      <div className="product-details">
        <div className="product-images">
          {product.images &&
            product.images.map((imageGot, index) => (
              // eslint-disable-next-line
              <img
                key={index}
                src={imageGot}
                alt={`Product Image ${index + 1}`}
                onClick={() => handleImageClick(imageGot)}
              />
            ))}
        </div>
        <div className="product-info">
          <p className="product-brand">{product.brand}</p>
          <h1 style={{ color: "gray" }}>{product.title}</h1>
          <hr style={{ color: "gray", marginBottom: "80px" }} />
          <h3>Product Details</h3>
          <p className="product-text">{product.text}</p>
          <a
            href={product.buttonLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Buy Now
          </a>
        </div>
      </div>
      {expandedImage && (
        <div
          className="expanded-image-overlay"
          onClick={handleCloseExpandedImage}
        >
          <img src={expandedImage} alt="" />
        </div>
      )}
    </div>
  );
};

export default ProductPage;
