import React from "react";
import '../css/pages/about.css';

function About() {
  return (
    <div className="about-card">
      <h2>Welcome to MinnaStyleHub!</h2>
      <p>
        At MinnaStyleHub, we believe that every individual deserves to express
        their unique style and personality through their wardrobe choices.
        Founded by Minna T J with the inspiration to create an exceptional fashion platform,
        our online store is dedicated to providing a curated selection of
        trendy and timeless fashion pieces that empower you to look and feel
        your best, every day.
      </p>
      <h3>Our Mission</h3>
      <p>
        Our mission at MinnaStyleHub is simple: to offer an unparalleled
        shopping experience that combines high-quality fashion, affordability,
        and convenience. Whether you're searching for the perfect outfit for a
        special occasion or refreshing your everyday essentials, we strive to be
        your go-to destination for all your fashion needs.
      </p>
      <h3>What Sets Us Apart</h3>
      <p>
        What sets MinnaStyleHub apart is our commitment to delivering not just
        stylish clothing, but also a personalized shopping journey. With a keen
        eye for the latest trends and a dedication to customer satisfaction, we
        handpick each item in our collection to ensure exceptional quality and
        style. Plus, our user-friendly website makes it easy to explore our wide
        range of products, find inspiration, and make your purchases with
        confidence.
      </p>
      <h3>Our Promise</h3>
      <p>
        When you shop at MinnaStyleHub, you can trust that you're investing in
        more than just clothing. You're investing in a vision of fashion that
        celebrates individuality, creativity, and self-expression. We're here to
        help you discover your signature style and embrace it with confidence,
        because we believe that fashion should be fun, accessible, and
        empowering for everyone.
      </p>
      <h3>Get in Touch</h3>
      <p>
        Have questions, feedback, or just want to say hello? We'd love to hear
        from you! Feel free to reach out to our friendly customer service team
        at{" "}
        <a href="mailto:minnastylehub@gmail.com">minnastylehub@gmail.com</a>{" "}
        and we'll be happy to assist you.
      </p>
      <p>
        Thank you for choosing MinnaStyleHub as your fashion destination. Here's
        to embracing style, confidence, and endless possibilities together!
      </p>
    </div>
  );
}

export default About;
