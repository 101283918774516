import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  FormControl,
  Pagination,
} from "react-bootstrap";
import axios from "axios"; // Import axios for making HTTP requests
import "../../css/admin/dashboard.css";
import { useNavigate } from "react-router-dom"; // Import useHistory for redirection
import SpinnerComponent from "../../components/SpinnerComponent";

function Dashboard({ backendUrl }) {
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const history = useNavigate(); // Get history object for redirection
  const [formData, setFormData] = useState({
    title: "",
    text: "",
    brand: "",
    images: [],
    buttonLink: "",
  });
  const [page, setPage] = useState(1); // Current page number
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10); // Number of items per page

  useEffect(() => {
    // Check if a token is stored in localStorage
    const token = localStorage.getItem("token");
    if (token) {
      fetchData(page, limit); // Fetch data when component mounts
    } else {
      history("/admin-login");
    }
    // eslint-disable-next-line
  }, [history, page, limit]);

  // Function to fetch data from the server
  const fetchData = async (page) => {
    try {
      setLoading(true);
      const response = await fetch(backendUrl+`/items?page=${page}&limit=${limit}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const jsonData = await response.json();
      setData(jsonData.result);
      setTotalPages(Math.ceil(jsonData.meta.count / limit)); // Calculate total pages
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }

  function truncateLink(link, maxLength) {
    if (link.length > maxLength) {
      return link.substring(0, maxLength) + "...";
    }
    return link;
  }

  // Function to handle form input changes
  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const newImages = [...formData.images];
    newImages[index] = value;
    setFormData({ ...formData, images: newImages });
  };

  // Function to handle form submission for adding/editing items
  const handleFormSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      if (formData._id) {
        setBtnLoading(true);
        // If formData has an id, it means we're editing an existing item
        await axios.put(backendUrl + `/items/update`, formData, {
          headers: {
            Authorization: token,
          },
        });
      } else {
        // If formData doesn't have an id, it means we're adding a new item
        setBtnLoading(true);
        await axios.post(backendUrl + "/items/add", formData, {
          headers: {
            Authorization: token,
          },
        });
      }
      fetchData(page, limit); // Refresh data after adding/editing
      setIsModalVisible(false);
      setBtnLoading(false);
      // Reset form data after submission
      setFormData({
        id: null,
        title: "",
        text: "",
        brand: "",
        images: [],
        buttonLink: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleFormClear = async () => {
    setFormData({
      id: null,
      title: "",
      text: "",
      brand: "",
      images: [],
      buttonLink: "",
    });
  }

  // Function to handle deletion of an item
  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");
      setLoading(true);
      await axios.delete(backendUrl + `/items/${id}`, {
        headers: {
          Authorization: token,
        },
      });
      fetchData(page, limit); // Refresh data after deletion
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handlePagination = (pageNum) => {
    setPage(pageNum); // Update current page number
  };

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value)); // Update limit
    setPage(1); // Reset page to 1 when limit changes
  };

  // Function to handle editing of an item
  const handleEdit = (id) => {
    const selectedItem = data.find((item) => item._id === id);
    setFormData(selectedItem);
    setIsModalVisible(true);
  };

  // Function to handle logging out
  const funcLogout = () => {
    localStorage.removeItem("token");
    history("/admin-login");
  };

  if (loading) {
    return <SpinnerComponent />;
  }

  const columns = [
    {
      dataField: "images",
      text: "Image",
      formatter: (images) => (
        <img
          src={images[0]}
          alt="First"
          style={{ width: "200px", height: "300px" }}
        />
      ),
    },
    {
      dataField: "title",
      text: "Title",
    },
    {
      dataField: "text",
      text: "Text",
      formatter: (_, record) => truncateText(record.text, 50), // Truncate text to 50 characters
    },
    {
      dataField: "brand",
      text: "Brand",
    },
    {
      dataField: "buttonLink",
      text: "Button Link",
      formatter: (_, record) => (
        <a href={record.buttonLink} target="_blank" rel="noreferrer">
          {truncateLink(record.buttonLink, 30)}
        </a>
      ),
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: (_, record) => (
        <>
          <Button variant="primary" onClick={() => handleEdit(record._id)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pencil"
              viewBox="0 0 16 16"
            >
              <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
            </svg>
          </Button>
          <Button variant="danger" onClick={() => handleDelete(record._id)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-trash"
              viewBox="0 0 16 16"
            >
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
            </svg>
          </Button>
        </>
      ),
    },
  ];

  const filteredData = data.filter((item) =>
    item.title.toLowerCase().includes(searchTitle.toLowerCase())
  );


  const paginationComponent = (
    <Pagination className="black-pagination">
      <Pagination.First
        className="custom-pagination-item"
        onClick={() => handlePagination(1)}
      />
      <Pagination.Prev
        className="custom-pagination-item"
        onClick={() => handlePagination(Math.max(page - 1, 1))}
      />
      {[...Array(totalPages)].map((_, i) => (
        <Pagination.Item
          key={i}
          className="custom-pagination-item"
          active={i + 1 === page}
          onClick={() => handlePagination(i + 1)}
        >
          {i + 1}
        </Pagination.Item>
      ))}
      <Pagination.Next
        className="custom-pagination-item"
        onClick={() => handlePagination(Math.min(page + 1, totalPages))}
      />
      <Pagination.Last
        className="custom-pagination-item"
        onClick={() => handlePagination(totalPages)}
      />
    </Pagination>
  );

  return (
    <div className="dashboard">
      <h2 className="dashboard-heading">Dashboard</h2>
      <div style={{ display: "flex" }}>
        <Form.Group controlId="limitSelect" style={{ marginBottom: "10px" }}>
          <Form.Label>Show per page:</Form.Label>
          <Form.Control as="select" value={limit} onChange={handleLimitChange}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </Form.Control>
        </Form.Group>
      </div>
      <Form.Group
        controlId="searchTitle"
        style={{ marginBottom: "10px", width: "30vw" }}
      >
        <FormControl
          type="text"
          placeholder="Search by Title"
          value={searchTitle}
          onChange={(e) => setSearchTitle(e.target.value)}
        />
      </Form.Group>

      <Button
        variant="primary"
        onClick={() => setIsModalVisible(true)}
        style={{ marginBottom: "10px", marginRight: "10px" }}
      >
        Add New Item
      </Button>

      <Button
        variant="danger"
        onClick={() => funcLogout(true)}
        style={{ marginBottom: "10px" }}
      >
        Logout
      </Button>
      <Modal
        show={isModalVisible}
        onHide={() => setIsModalVisible(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="dashboard-form">
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <FormControl
                type="text"
                name="title"
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="text">
              <Form.Label>Text</Form.Label>
              <FormControl
                as="textarea"
                rows={3}
                name="text"
                value={formData.text}
                onChange={(e) =>
                  setFormData({ ...formData, text: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="brand">
              <Form.Label>Brand</Form.Label>
              <FormControl
                type="text"
                name="brand"
                value={formData.brand}
                onChange={(e) =>
                  setFormData({ ...formData, brand: e.target.value })
                }
              />
            </Form.Group>
            {[...Array(6)].map((_, index) => (
              <Form.Group key={index} controlId={`image-${index}`}>
                <Form.Label>Image {index + 1}</Form.Label>
                <FormControl
                  type="text"
                  name={`image-${index}`}
                  value={formData.images[index] || ""}
                  onChange={(e) => handleInputChange(e, index)}
                />
              </Form.Group>
            ))}
            <Form.Group controlId="buttonLink">
              <Form.Label>Button Link</Form.Label>
              <FormControl
                type="text"
                name="buttonLink"
                value={formData.buttonLink}
                onChange={(e) =>
                  setFormData({ ...formData, buttonLink: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button
            variant="warning"
            onClick={handleFormClear}
            disabled={btnLoading}
          >
            Clear Form
          </Button>
          <Button variant="secondary" onClick={() => setIsModalVisible(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleFormSubmit}
            disabled={btnLoading}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Table striped bordered hover className="dashboard-table">
        <thead>
          <tr>
            {columns.map((column, idx) => (
              <th key={idx}>{column.text}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item) => (
            <tr key={item._id}>
              <td>
                <img
                  src={item.images[0]}
                  alt="First"
                  style={{ width: "150px", height: "200px" }}
                />
              </td>
              <td>{truncateText(item.title, 40)}</td>
              <td>{truncateText(item.text, 30)}</td>
              <td>{item.brand}</td>
              <td>
                <a href={item.buttonLink} target="_blank" rel="noreferrer">
                  {truncateLink(item.buttonLink, 30)}
                </a>
              </td>
              <td>
                <Button
                  variant="primary"
                  onClick={() => handleEdit(item._id)}
                  style={{ margin: "5px" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-pencil"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                  </svg>
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(item._id)}
                  style={{ margin: "5px" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                  </svg>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {paginationComponent}
    </div>
  );
}

export default Dashboard;
